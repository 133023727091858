.module-txt {
    h2 {
        text-align: center;
        margin-bottom: 30px;
    }
    p {
        margin-bottom: 12px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    a {
        text-decoration: underline;
        //color: $color-beige;
        &:hover {
            color: $color-beige;

        }
    }
    a[href^="tel:"] {
      //color: $color-black;
      text-decoration: none;
    }

    a[href^="mailto"] {
      //color: $color-black;
      text-decoration: none;
    }

    img {
      fill: $color-black;
    }
}

.module-linie {
    img {
        //max-width: 320px;
        margin: 0 auto;
    }
}

.module-header {
    margin-bottom: 40px;
}

.module-footer {
    margin-top: 40px;
}

.ext_button {
    display: block;
    margin: 40px auto 0 auto;
    img {
        margin: 0 auto;
    }
}

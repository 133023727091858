@media only screen and (min-width: 800px) {

  .wrapper {
    &.fixed-nav {
      padding-top: 100px;
    }
  }

  .body-home {
      .wrapper {
        &.fixed-nav {
          padding-top: 80px;
        }
      }
  }

  .global-nav {
    transition: all 0.3s;
    &.shrink {
      z-index: 1000;
      background: $color-black;
      color: $color-white;
      a {
        &:hover {
          color: $color-beige;
        }
      }
      opacity: 0.9;
      -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
      top: 0;
      left: 0;
      right: 0;
      .globalnav__link {
          color: $color-white;
      }
      .globalnav__item--home {
        display: block;
      }
      .globalnav__list {
        padding: 8px 0;
      }
    }
  }

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 0.9; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 0.9; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 0.9; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 0.9; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 0.9; }
}

}

/**
 * @license
 * MyFonts Webfont Build ID 3339726, 2017-02-02T09:06:06-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: FF Yoga Sans Web by FontFont
 * URL: http://www.myfonts.com/fonts/fontfont/yoga-sans-pro/ot-regular/
 *
 * Webfont: FF Yoga Sans Web Bold Italic by FontFont
 * URL: http://www.myfonts.com/fonts/fontfont/yoga-sans-pro/ot-bold-italic/
 *
 * Webfont: FF Yoga Sans Web Bold by FontFont
 * URL: http://www.myfonts.com/fonts/fontfont/yoga-sans-pro/ot-bold/
 *
 * Webfont: FF Yoga Sans Web Italic by FontFont
 * URL: http://www.myfonts.com/fonts/fontfont/yoga-sans-pro/ot-regular-italic/
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3339726
 * Licensed pageviews: 500,000
 * Webfonts copyright: 2009 Xavier Dupr&#x00E9; published by FSI FontShop International GmbH
 *
 * © 2017 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/32f5ce");


@font-face {font-family: 'FFYogaSansWeb';src: url('../webfonts/32F5CE_0_0.eot');src: url('../webfonts/32F5CE_0_0.eot?#iefix') format('embedded-opentype'),url('../webfonts/32F5CE_0_0.woff2') format('woff2'),url('../webfonts/32F5CE_0_0.woff') format('woff'),url('../webfonts/32F5CE_0_0.ttf') format('truetype');}


@font-face {font-family: 'FFYogaSansWebBoldItalic';src: url('../webfonts/32F5CE_1_0.eot');src: url('../webfonts/32F5CE_1_0.eot?#iefix') format('embedded-opentype'),url('../webfonts/32F5CE_1_0.woff2') format('woff2'),url('../webfonts/32F5CE_1_0.woff') format('woff'),url('../webfonts/32F5CE_1_0.ttf') format('truetype');}


@font-face {font-family: 'FFYogaSansWebBold';src: url('../webfonts/32F5CE_2_0.eot');src: url('../webfonts/32F5CE_2_0.eot?#iefix') format('embedded-opentype'),url('../webfonts/32F5CE_2_0.woff2') format('woff2'),url('../webfonts/32F5CE_2_0.woff') format('woff'),url('../webfonts/32F5CE_2_0.ttf') format('truetype');}


@font-face {font-family: 'FFYogaSansWebItalic';src: url('../webfonts/32F5CE_3_0.eot');src: url('../webfonts/32F5CE_3_0.eot?#iefix') format('embedded-opentype'),url('../webfonts/32F5CE_3_0.woff2') format('woff2'),url('../webfonts/32F5CE_3_0.woff') format('woff'),url('../webfonts/32F5CE_3_0.ttf') format('truetype');}

@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;


/* Arrows */
.slick-prev,
.slick-next {
    position: absolute;
    z-index: 666;
    display: block;
    height: 44px;
    width: 30px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    //background-color: rgba(#000, 0.5);
    background: transparent;

    &:hover, &:focus {
        outline: none;
        color: transparent;
        //background-color: rgba(#000, 0.3);
    }
}
.slick-prev {
    left: 6px;

}
.slick-next {
    right: 6px;

}


.slick-prev:before,
.slick-next:before {
    content: ' ';
    display: none;
    position: absolute;
    top: 6px;
    left: 6px;
    z-index: 777;
    width: 18px;
    height: 32px;
}

.slick-prev:before {
    background-image: url('../images/prev.png');
    background-repeat: no-repeat;
}
.slick-next:before {
    background-image: url('../images/next.png');
    background-repeat: no-repeat;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: 0.2;
}

@media screen and (min-width: 800px) {

    .slick-prev:before,
    .slick-next:before {
        display: block;
    }

}

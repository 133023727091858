.social-list__item {
  margin-top: 3px;
  display: block;
  width: 20px;
  height: 20px;
  text-indent: -999px;
  overflow: hidden;
  float: left;
  margin-right: 10px;
  background-size: 20px 40px;
  background-position: 0 0;
  background-image: url('../images/social.png');
  &:last-child {
    margin-right: 0;
  }
  a {
      display: block;
  }
}

.social-list__item--fb {
  background-position: 0 0;
}

.social-list__item--insta {
  background-position: 0 -20px;
}

.module-icon {
    text-align: center;
    img {
        //max-height: 160px;
        max-height: 150px;
    }
}
.module-icon__image {
    img {
        margin: 0 auto;
    }
}

.module-icon__link {
    display: inline-block;
    img {
        margin: 0 auto 16px auto;
    }
    span {
        font-family: 'FFYogaSansWebBold', sans-serif;
        line-height: 1.2;
        //letter-spacing: 1px;
    }
}

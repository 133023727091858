body {
}

.wrapper {
    width: 100%;
    display: flex;
    min-height: 100vh;
    //height: 100%;
    flex-direction: column;
    position: relative;
    min-width: 320px;
}

.wrapper-inner {
    //flex: 1;
    flex-grow:1;
}

.module {
    margin: 30px auto 0 auto;
    &:first-child {
        margin-top: 0;
    }
}

.module-narrow {
    max-width: $content-width;
    padding: 0 20px;
}

.module-wide {
    max-width: 1440px;
}

.module-icon + .module-txt,
.module-hero + .module-txt,
.module-txt + .module-txt {
    margin-top: 80px;
}

.module-icon + .module-icon {
    margin-top: 40px;
}

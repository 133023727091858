.global-nav {
  display: none;
  text-align: center;
  &.active {
    display: block;
  }
}

.nav-linie {
  padding: 20px 0;
  img {
      max-width: 320px;
      margin: 0 auto;
  }
}

.nav-footer {
  padding-bottom: 35px;
}

.globalnav__list {
    line-height: 40px;
}

.globalnav__link {
    color: $color-white;
    font-family: 'FFYogaSansWebBold', sans-serif;
    @include font-size(20px);
    letter-spacing: 1px;
}

.globalnav__item--home,
.globalnav__item--lang {
  display: none;
}

.globalnav__link--home {
    display: block;
    width: 169px;
    height: 40px;
    text-indent: -9999px;
    overflow: hidden;
    background-image: url(../images/logo_portofino_neg@2x.png);
    background-size: 169px 40px;
}

.globalnav__link--lang {
    font-family: 'FFYogaSansWebItalic', sans-serif;
    @include font-size(16px);
    text-transform: capitalize;
}

@media only screen and (min-width: 800px) {
  .global-nav {
    display: block;
    text-align: left;
    &.shrink {
      position: fixed;
    }
  }

  .nav-linie {
    display: none;
  }

  .globalnav__list {
      display: block;
      text-align: center;
      padding: 30px 0;
      line-height: 40px;
      display: flex;
      justify-content: center;
  }

  .globalnav__item {
      //display: inline-block;
      margin-right: 20px;
      float: left;
    }

  .site-header--home {
      .globalnav__list {
         padding: 20px 0;
     }
  }

  .globalnav__item {
      //display: inline-block;
      margin-right: 20px;
      height: 40px;
      &:last-child {
          margin-right: 0;
      }
  }

  .globalnav__item--home {
    margin-right: 40px;
    display: block;
  }

  .globalnav__item--lang {
    margin-left: 40px;
    display: block;
  }

  .no-touchevents {
    .globalnav__link {
      &:hover {
        color: $color-beige;
      }
    }
  }

}

.mobilenav__list {
  display: block;
  padding-top: 60px;
  li {
    display: block;
    position: absolute;
    // top: 50%;
    // -webkit-transform: translate(0%, -50%);
    // transform: translate(0%, -50%);
  }
}

.mobilenav__logo {
  top: 11px;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
}

.mobilenav__lang {
  top: 15px;
  left: 10px;
}

.mobilenav__toggle {
  top: 18px;
  right: 10px;
}

@media only screen and (min-width: 800px) {

  .mobilenav__list {
    display: none;
  }

}

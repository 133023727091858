.form-newsletter {
    margin-top: 3px;
}

.form__input {
    -webkit-border-radius:0px;
    border: none;
    width: 100%;
    padding: 5px;
    color: $color-black;
}

*::-webkit-input-placeholder {
    color: $color-black;
}
*:-moz-placeholder {
    /* FF 4-18 */
    color: $color-black;
}
*::-moz-placeholder {
    /* FF 19+ */
    color: $color-black;
}
*:-ms-input-placeholder {
    /* IE 10+ */
    color: $color-black;
}

.form__submit {
    line-height: 1;
    -webkit-border-radius:0px;
    //margin-top: 10px;
    //border: 2px solid $color-rot;
    border: none;
    background-color: $color-black;
    padding: 0;
    padding-top: 7px;
    color: #fff;
    outline: none;
    &:hover {
        //background-color: $color-blau;
        color: $color-beige;
    }
}

.error {
    margin-top: 5px;
    color: $color-beige;
}

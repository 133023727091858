.site-header {
    background-color: $color-black;
    color: $color-white;
    //margin-bottom: 80px;
    position: relative;

}

.logo-home {
    display: none;
}

@media only screen and (min-width: 800px) {

  .site-header--home {
      background-color: $color-white;
      color: $color-black;
      .globalnav__link {
          color: $color-black;
      }
      .globalnav__item--home {
        display: none;
      }
      .logo-home {
          max-width: 960px;
          width: 100%;
          display: block;
          margin: 40px auto 0 auto;
          padding: 0 20px;
      }
  }

}

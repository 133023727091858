.col2-wrap {
  margin: 80px auto;
  .module-txt {
    text-align: center;
  }
  .col2 {
    margin-top: 80px;
  }
}

@media only screen and (min-width: 800px) {
  .flexbox {

    .col2-wrap {
      .module-icon {
        img {
          max-height: 120px;
        }
      }
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      .col {
        width: 50%;
        max-width: 320px;
        .module-narrow {
          padding: 0;
        }
        .module-txt {
          display: flex;
          flex-direction: column;
          text-align: left;
          h2 {
              text-align: left;
              margin-bottom: 30px;
          }

          .module-footer {
            margin-top: auto;
            padding-top: 40px;
          }
        }
      }
      .col1 {
        padding-right: 40px;
      }
      .col2 {
        padding-left: 40px;
        margin-top: 0;
      }
    }
  }
}

body {
    font-family: 'FFYogaSansWeb', sans-serif;
    //@include font-size(22px);
    //line-height: (34/22);
    @include font-size(18px);
    line-height: 1.4;
    color: $color-black;
}

a {
    text-decoration: none;
    color: $color-black;
    transition: all 0.3s;
    &:hover {
    }
}


strong {
    font-family: 'FFYogaSansWebBold', sans-serif;
    letter-spacing: 1px;
}

h1, h2 {
    font-family: 'FFYogaSansWebBold', sans-serif;
    //@include font-size(66px);
    //line-height: (80/66);
    @include font-size(42px);
    line-height: 1.2;
}

italic {
    font-family: 'FFYogaSansWebItalic', sans-serif;
}

@media only screen and (min-width: 800px) {

}
// html {
//   height: 100%;
// }

// body {
//     min-height: 100%;
// }

.clearfix:before,
.clearfix:after {
     content: " "; /* 1 */
     display: table; /* 2 */
}
.clearfix:after {
     clear: both;
}
.clearfix {
     *zoom: 1;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

img {
    max-width: 100%;
    display: block;
    //height: auto;
}
iframe {
    max-width: 100%;
}

* {
    min-height: 0;
    min-width: 0;
}

button:focus {outline:0;}

svg {
  height: 100%;
  width: 100%;
  -moz-transform: scale(1);
  transform: scale(1)
}

.ie9 img[src$=".svg"] {
  width: 100%;
}
/* 2 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$=".svg"] {
    width: 100%;
  }
}

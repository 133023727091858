.site-footer {
    background-color: $color-black;
    color: $color-white;
    margin-top: 80px;
    @include font-size(16px);
    line-height: 1.32;
    a {
        color: $color-white;
        &:hover {
            color: $color-beige;
        }
    }
}

.footer-content {
    max-width: 1000px;
    margin: 20px auto 40px auto;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-item {
    width: 100%;
    margin-bottom: 20px;
}

.title-footer {
    font-family: 'FFYogaSansWebBold', sans-serif;
    display: block;
}

.footer-item--single {
    .title-footer {
        margin-bottom: 20px;
    }
}

.footer-item--multiple {
    .title-footer {
        margin-top: 20px;
        margin-bottom: 5px;
        &:first-child {
            margin-top: 0;
        }
    }
}

@media only screen and (min-width: 440px) {
    .footer-item {
        width: 50%;
    }
}

@media only screen and (min-width: 800px) {
    .footer-item {
        width: 25%;
        margin-bottom: 0;
    }
}
